import {
  darken,
  desaturate,
  lighten,
  readableColor,
  transparentize,
} from "polished";

const PlandayTheme = {
  spacing: {
    unit: 5,
    sectionHorizontal: ({ spacing }) => spacing.unit * 8,
    sectionVertical: ({ spacing }) => spacing.unit * 3,
  },
  breakpoints: {
    small: "50rem",
    medium: "85rem",
    large: "105rem",
  },
  colors: {
    tonalOffset: 0.3,
    primary: {
      main: "#2B3746",
      light: ({ colors }) => lighten(colors.tonalOffset, colors.primary.main),
      dark: ({ colors }) => darken(colors.tonalOffset, colors.primary.main),
      contrastText: ({ colors }) => readableColor(colors.primary.main),
    },
    success: {
      main: "#00aa13",
      light: ({ colors }) => lighten(colors.tonalOffset, colors.success.main),
      dark: ({ colors }) => darken(colors.tonalOffset, colors.success.main),
      contrastText: ({ colors }) => readableColor(colors.success.main),
    },
    warning: {
      main: "#d4ad03",
      light: ({ colors }) => lighten(colors.tonalOffset, colors.warning.main),
      dark: ({ colors }) => darken(colors.tonalOffset, colors.warning.main),
      contrastText: "#ffffff",
    },
    error: {
      main: "#e53935",
      light: ({ colors }) => lighten(colors.tonalOffset, colors.error.main),
      dark: ({ colors }) => darken(colors.tonalOffset, colors.error.main),
      contrastText: ({ colors }) => readableColor(colors.error.main),
    },
    text: {
      primary: "#2B3746",
      secondary: ({ colors }) =>
        lighten(colors.tonalOffset, colors.text.primary),
    },
    border: {
      dark: "rgba(0,0,0, 0.1)",
      light: "#ffffff",
    },
    responses: {
      success: {
        color: ({ colors }) => colors.success.main,
        backgroundColor: ({ colors }) =>
          transparentize(0.9, colors.success.main),
      },
      error: {
        color: ({ colors }) => colors.error.main,
        backgroundColor: ({ colors }) => transparentize(0.9, colors.error.main),
      },
      redirect: {
        color: "#ffa500",
        backgroundColor: ({ colors }) =>
          transparentize(0.9, colors.responses.redirect.color),
      },
      info: {
        color: "#87ceeb",
        backgroundColor: ({ colors }) =>
          transparentize(0.9, colors.responses.info.color),
      },
    },
    http: {
      get: "#0DC05F",
      post: "#199BD7",
      put: "#79899A",
      options: "#d3ca12",
      patch: "#e09d43",
      delete: "#e27a7a",
      basic: "#999",
      link: "#31bbb6",
      head: "#c167e4",
    },
  },
  schema: {
    linesColor: (theme) =>
      lighten(
        theme.colors.tonalOffset,
        desaturate(theme.colors.tonalOffset, theme.colors.primary.main)
      ),
    defaultDetailsWidth: "75%",
    typeNameColor: (theme) => theme.colors.text.secondary,
    typeTitleColor: (theme) => theme.schema.typeNameColor,
    requireLabelColor: (theme) => theme.colors.error.main,
    labelsTextSize: "0.9em",
    nestingSpacing: "1em",
    nestedBackground: "#fafafa",
    arrow: {
      size: "1.1em",
      color: (theme) => theme.colors.text.secondary,
    },
  },
  typography: {
    fontSize: "12px",
    lineHeight: "1.5em",
    fontWeightRegular: "500",
    fontWeightBold: "600",
    fontWeightLight: "500",
    fontFamily: "Avenir Next W01",
    smoothing: "antialiased",
    optimizeSpeed: true,
    headings: {
      fontFamily: "Avenir Next W01",
      fontWeight: "600",
      lineHeight: "1.6em",
    },
    code: {
      fontSize: "12px",
      fontFamily: "Courier, monospace",
      lineHeight: ({ typography }) => typography.lineHeight,
      fontWeight: ({ typography }) => typography.fontWeightRegular,
      color: "#e53935",
      backgroundColor: "rgba(38, 50, 56, 0.05)",
      wrap: false,
    },
    links: {
      color: "#199BD7",
      visited: "#199BD7",
      hover: (_) => lighten(0.2, "#199BD7"),
    },
  },
  menu: {
    width: "260px",
    backgroundColor: "#fafafa",
    textColor: "#333333",
    groupItems: {
      textTransform: "uppercase",
    },
    level1Items: {
      textTransform: "none",
    },
    arrow: {
      size: "1.5em",
      color: (theme) => theme.menu.textColor,
    },
  },
  logo: {
    maxHeight: ({ menu }) => menu.width,
    maxWidth: ({ menu }) => menu.width,
    gutter: "2px",
  },
  rightPanel: {
    backgroundColor: "#2B3746",
    width: "40%",
    textColor: "#ffffff",
  },
  codeSample: {
    backgroundColor: ({ rightPanel }) =>
      darken(0.1, rightPanel.backgroundColor),
  },
};

export default PlandayTheme;
