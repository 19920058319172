/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "theme-ui";
import { RedocStandalone } from "redoc";
import PlandayTheme from "../../planday-theme-redoc/theme.js";
import axios from "axios";
import { useState, useEffect } from "react";
import {getBaseUrl} from "../../../../src/gatsby-theme-docz/utils/urlHelpers"
const HEADER_HEIGHT = 48;

const urlParams = typeof window !== "undefined" ? new URLSearchParams(window.location.search) : null

const RedocWrapper = ({ apiName }) => {

  const [jsonOutput, setJsonOutput] = useState(null);
  const [apiVersion, setApiVersion] = useState(urlParams?.get('version') ?? "v1.0")

  useEffect(() => {

    axios
      .get(`${getBaseUrl()}/${apiName}/swagger/${apiVersion}/swagger.json`)
      .then(function(response) {
        setJsonOutput(mutateSwagger(response.data));
      })
      .catch(function(error) {
        // handle error
        console.log(error);
        setApiVersion("v1")
      });
  }, [apiName, apiVersion]);

  return jsonOutput ? (
    <RedocStandalone
      spec={jsonOutput}
      options={{
        nativeScrollbars: true,
        disableSearch: true,
        noAutoAuth: true,
        hideDownloadButton: true,
        hideLoading: true,
        jsonSampleExpandLevel: "all",
        theme: PlandayTheme,
        scrollYOffset: HEADER_HEIGHT,
      }}
    />
  ) : null;
};


const mutateSwagger = (swagger) => {
  // Add default server for calls
  if (!swagger.servers) {
    swagger.servers = [
      {
        url: getBaseUrl(),
      },
    ];
  }

  // Remove required security block from swagger file
  swagger.security = null;

  // Remove text/plain repsonse type
  for (var path in swagger.paths) {
    for (var method in swagger.paths[path]) {
      var response = swagger.paths[path][method].responses[200];
      if (response && response.content && response.content["text/plain"]) {
        delete response.content["text/plain"];
      }
    }
  }

  return swagger;
};

RedocWrapper.propTypes = {
  apiName: PropTypes.string,
};

export default RedocWrapper;
