export const white = '#FFFFFF';
export const grayUltraLight = '#FCFBFA';
export const grayExtraLight = '#F5F6F7';
export const plandayLightBlue = '#199bd7';
export const plandayDarkBlue = '#192533';
export const plandayDarkerBlue = '#168BC1';
export const grayLight = '#CED4DE';
export const gray10 = '#F5F7F9';
export const gray20 = '#E9EEF1';
export const gray = '#67788a';
export const grayDark = '#2D3747';
export const grayExtraDark = '#1D2330';
export const grayDarkKnight = '#16222D';
export const dark = '#13161F';
export const blueLight = '#e9f2fa';
export const blue = '#0B5FFF';
export const blue10 = '#DBF1FA';
export const skyBlue = '#1FB6FF';
export const negative = '#EB4D4B';
export const black = '#000000';

export const grayMenuHover = '#2B3746';
