// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---content-404-404-mdx": () => import("./../../../../content/404/404.mdx" /* webpackChunkName: "component---content-404-404-mdx" */),
  "component---content-api-absence-mdx": () => import("./../../../../content/api/absence.mdx" /* webpackChunkName: "component---content-api-absence-mdx" */),
  "component---content-api-contractrules-mdx": () => import("./../../../../content/api/contractrules.mdx" /* webpackChunkName: "component---content-api-contractrules-mdx" */),
  "component---content-api-hr-mdx": () => import("./../../../../content/api/hr.mdx" /* webpackChunkName: "component---content-api-hr-mdx" */),
  "component---content-api-pay-mdx": () => import("./../../../../content/api/pay.mdx" /* webpackChunkName: "component---content-api-pay-mdx" */),
  "component---content-api-payroll-mdx": () => import("./../../../../content/api/payroll.mdx" /* webpackChunkName: "component---content-api-payroll-mdx" */),
  "component---content-api-portal-mdx": () => import("./../../../../content/api/portal.mdx" /* webpackChunkName: "component---content-api-portal-mdx" */),
  "component---content-api-punchclock-mdx": () => import("./../../../../content/api/punchclock.mdx" /* webpackChunkName: "component---content-api-punchclock-mdx" */),
  "component---content-api-reports-mdx": () => import("./../../../../content/api/reports.mdx" /* webpackChunkName: "component---content-api-reports-mdx" */),
  "component---content-api-revenue-mdx": () => import("./../../../../content/api/revenue.mdx" /* webpackChunkName: "component---content-api-revenue-mdx" */),
  "component---content-api-schedule-mdx": () => import("./../../../../content/api/schedule.mdx" /* webpackChunkName: "component---content-api-schedule-mdx" */),
  "component---content-api-securitygroupmembership-mdx": () => import("./../../../../content/api/securitygroupmembership.mdx" /* webpackChunkName: "component---content-api-securitygroupmembership-mdx" */),
  "component---content-gettingstarted-api-support-mdx": () => import("./../../../../content/gettingstarted/api-support.mdx" /* webpackChunkName: "component---content-gettingstarted-api-support-mdx" */),
  "component---content-gettingstarted-authorization-flow-mdx": () => import("./../../../../content/gettingstarted/authorization-flow.mdx" /* webpackChunkName: "component---content-gettingstarted-authorization-flow-mdx" */),
  "component---content-gettingstarted-authorization-mdx": () => import("./../../../../content/gettingstarted/authorization.mdx" /* webpackChunkName: "component---content-gettingstarted-authorization-mdx" */),
  "component---content-gettingstarted-become-a-product-partner-mdx": () => import("./../../../../content/gettingstarted/become-a-product-partner.mdx" /* webpackChunkName: "component---content-gettingstarted-become-a-product-partner-mdx" */),
  "component---content-gettingstarted-overview-mdx": () => import("./../../../../content/gettingstarted/overview.mdx" /* webpackChunkName: "component---content-gettingstarted-overview-mdx" */),
  "component---content-gettingstarted-rate-limiting-mdx": () => import("./../../../../content/gettingstarted/rate-limiting.mdx" /* webpackChunkName: "component---content-gettingstarted-rate-limiting-mdx" */),
  "component---content-gettingstarted-releasenotes-mdx": () => import("./../../../../content/gettingstarted/releasenotes.mdx" /* webpackChunkName: "component---content-gettingstarted-releasenotes-mdx" */),
  "component---content-guides-errors-mdx": () => import("./../../../../content/guides/errors.mdx" /* webpackChunkName: "component---content-guides-errors-mdx" */),
  "component---content-guides-hr-mdx": () => import("./../../../../content/guides/hr.mdx" /* webpackChunkName: "component---content-guides-hr-mdx" */),
  "component---content-guides-payroll-mdx": () => import("./../../../../content/guides/payroll.mdx" /* webpackChunkName: "component---content-guides-payroll-mdx" */),
  "component---content-guides-plandaystructure-mdx": () => import("./../../../../content/guides/plandaystructure.mdx" /* webpackChunkName: "component---content-guides-plandaystructure-mdx" */),
  "component---content-guides-punchclock-mdx": () => import("./../../../../content/guides/punchclock.mdx" /* webpackChunkName: "component---content-guides-punchclock-mdx" */),
  "component---content-guides-revenue-mdx": () => import("./../../../../content/guides/revenue.mdx" /* webpackChunkName: "component---content-guides-revenue-mdx" */),
  "component---content-guides-timeandcost-mdx": () => import("./../../../../content/guides/timeandcost.mdx" /* webpackChunkName: "component---content-guides-timeandcost-mdx" */),
  "component---content-welcome-index-mdx": () => import("./../../../../content/welcome/index.mdx" /* webpackChunkName: "component---content-welcome-index-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

