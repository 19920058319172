/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import { useMenus } from "docz";

import * as styles from "./styles";
import openapiSrc from "../../images/openapi.svg";

const WelcomePage = () => {
  const apis = useMenus("").find((x) => x.name === "API").menu;
  const gettingStartedOverview = useMenus("")
    .find((menu) => menu.name === "Getting started")
    .menu.find((subMenu) => subMenu.name === "Overview");

  const list = [gettingStartedOverview, ...apis];

  return (
    <Box sx={styles.wrapper}>
      <img src={openapiSrc} sx={styles.openapiImage} alt="Planday Open API" />
      <h1>Create powerful integrations with Planday!</h1>
      <p sx={styles.description}>
        Planday’s API offers a world of opportunities to support your business.
        Learn how to get started or navigate directly to our APIs and explore
        the options.
      </p>
      <div sx={styles.cardsWrapper}>
        {list &&
          list.map((el) => (
            <div
              key={el.id}
              sx={styles.styledCard}
              onClick={() => window.location = el.route}
              aria-hidden="true"
            >
              <div sx={styles.styledCircle}>
                <img
                  sx={styles.styledIcon(el.iconFileName)}
                  src={`../public/icons/${el.iconFileName}.svg`}
                  alt="icon"
                />
              </div>
              <div sx={styles.contentSection}>
                <div sx={styles.styledCardTextSection}>
                  <div sx={styles.styledCardTitle}>
                    {el.name === "Overview" ? "Getting started" : el.name}
                  </div>
                  <div sx={styles.styledCardText}>{el.description}</div>
                </div>
                <div sx={(styles.styledCardTextSection, styles.arrowSection)}>
                  <div sx={styles.styledCardArrow}>
                    <img src={`../public/icons/arrow.svg`} alt="arrow icon" />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </Box>
  );
};

export default WelcomePage;
