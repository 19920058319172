/** @jsx jsx */
import { jsx, Box } from "theme-ui";

import * as styles from "./styles";

import Button from "../../../../src/gatsby-theme-docz/components/Button";
import astronautSrc from "../../images/astronaut.svg";
import satelliteSrc from "../../images/satellite.svg";

const SpaceScene = () => (
  <Box sx={styles.wrapper}>
    <Box sx={styles.scene}>
      <img src={astronautSrc} sx={styles.astronaut} />
      <img src={satelliteSrc} sx={styles.satellite} />
    </Box>
    <Box sx={styles.errorDetailsContainer}>
      <p sx={styles.title}>Stuck in orbit</p>
      <p sx={styles.description}>Something is broken on the spaceship</p>
      <Button link="/" text="Take me home" type="primary" />
    </Box>
  </Box>
);

export default SpaceScene;
