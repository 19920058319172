import * as colors from "../../../../src/gatsby-theme-docz/theme/colors";

export const wrapper = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  background: `${colors.grayDarkKnight}`,
  color: `${colors.white}`,
};

export const scene = {
  position: "relative",
  display: "block",
  width: "567px",
  height: "430px",
  margin: "-15% auto 0",
};

export const astronaut = {
  position: "absolute",
  left: "84px",
  top: "95px",
};

export const satellite = {
  display: "block",
};

export const errorDetailsContainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

export const title = {
  fontSize: "1.75rem",
  fontWeight: 600,
  margin: "0 0 0.5rem 0",
};

export const description = {
  fontSize: "1.25rem",
  fontWeight: 400,
  margin: "0 0 1.5rem 0",
};
