module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"/usr/src/app/node_modules/gatsby-theme-docz/src/base/Layout.js"},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/usr/src/app/.docz"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{},"src":"./","gatsbyRoot":null,"themesDir":"src","mdxExtensions":[".md",".mdx"],"docgenConfig":{},"menu":[{"name":"Getting started","menu":["Overview","Authorization","Authorization code flow"]},{"name":"Guides","menu":["Employee management integrations","Point of Sale (POS) integrations","Time clock integrations","Payroll integrations","Time and Cost integrations","Errors","Planday structure"]},{"name":"API","menu":["Absence","Contract rules","HR","Pay","Payroll","Portal","Punchclock","Reports","Revenue","Schedule","Security group membership"]}],"mdPlugins":[],"hastPlugins":[],"ignore":["README.md"],"typescript":false,"ts":false,"propsParser":true,"props-parser":true,"debug":false,"native":false,"openBrowser":null,"o":null,"open":null,"open-browser":null,"root":"/usr/src/app/.docz","base":"/","source":"./","gatsby-root":null,"files":"**/*.{md,markdown,mdx}","public":"/public","dest":".docz/dist","d":".docz/dist","editBranch":"master","eb":"master","edit-branch":"master","config":"","title":"Planday Developer","description":"My awesome app using docz","host":"localhost","port":3000,"p":3000,"separator":"-","paths":{"root":"/usr/src/app","templates":"/usr/src/app/node_modules/docz-core/dist/templates","docz":"/usr/src/app/.docz","cache":"/usr/src/app/.docz/.cache","app":"/usr/src/app/.docz/app","appPackageJson":"/usr/src/app/package.json","appTsConfig":"/usr/src/app/tsconfig.json","gatsbyConfig":"/usr/src/app/gatsby-config.js","gatsbyBrowser":"/usr/src/app/gatsby-browser.js","gatsbyNode":"/usr/src/app/gatsby-node.js","gatsbySSR":"/usr/src/app/gatsby-ssr.js","importsJs":"/usr/src/app/.docz/app/imports.js","rootJs":"/usr/src/app/.docz/app/root.jsx","indexJs":"/usr/src/app/.docz/app/index.jsx","indexHtml":"/usr/src/app/.docz/app/index.html","db":"/usr/src/app/.docz/app/db.json"},"commonmark":true},
    }]
