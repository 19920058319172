import { media } from "~theme/breakpoints";

export const wrapper = {
  backgroundColor: "#F5F7F9",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  padding: "75px 0",
  [media.tablet]: {
    backgroundColor: "#fff",
  },
};

export const description = {
  marginBottom: "75px",
};

export const cardsWrapper = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "space-between",
  maxWidth: "1024px",
  padding: "0 10px",
  width: "100%",
  "> div": {
    width: "48.75%",
    marginBottom: "25px",
  },
  [media.tablet]: {
    "> div": {
      width: "100%",
      marginBottom: "25px",
    },
  },
};

/* Card component styles from the UI library */

export const styledCard = {
  padding: "1rem",
  backgroundColor: "#fff",
  position: "relative",
  borderRadius: "4px",
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  boxShadow:
    "0px 2px 8px rgba(13, 80, 110, 0.08), 0px 0px 1px rgba(13, 80, 110, 0.16)",
  ":hover": {
    boxShadow:
      "0px 8px 16px rgba(13, 80, 110, 0.04), 0px 4px 8px rgba(13, 80, 110, 0.08), 0px 0px 1px rgba(13, 80, 110, 0.16)",
  },
};

export const contentSection = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
};

export const styledCardTextSection = {
  flex: "1 1 auto",
};

export const styledCardTitle = {
  marginBottom: "0.25rem",
  fontSize: "12px",
  fontWeight: "600",
};

export const styledCardText = {
  color: "#758797",
  fontSize: "12px",
};

export const styledCardArrowSection = {
  flex: "0 0 2rem",
  marginLeft: "1rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const styledCardArrow = {
  height: "8px",
  width: "5px",
  display: "flex",
};

export const arrowSection = {
  display: "flex",
  alignItems: "center",
  width: "5px",
  margin: "0 10px 0 20px",
};

export const styledCircle = {
  background: "#f2f4f5",
  borderRadius: "50%",
  display: "flex",
  flex: "0 0 52px",
  marginRight: "1rem",
  height: "52px",
  justifyContent: "center",
  alignItems: "center",
};

export const styledIcon = (iconName) => {
  switch (iconName) {
    case "payroll":
      return {
        width: "33px",
        height: "32px",
      };
    default:
      return {
        width: "1.125rem",
        height: "1.125rem",
      };
  }
};
