import * as colors from '../../theme/colors';

export const wrapper = buttonType => {
  const styling = getStylingByButtonType(buttonType);

  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid transparent',
    borderRadius: '4px',
    fontWeight: 600,
    fontSize: '12px',
    textDecoration: 'none',
    height: '2.25rem',
    padding: '0 1rem',
    color: styling.color,
    backgroundColor: styling.backgroundColor,
    ':hover': {
      backgroundColor: styling.hover.backgroundColor,
      transition: styling.hover.transition,
      boxShadow: styling.hover.boxShadow,
    },
  };
};

const getStylingByButtonType = buttonType => {
  switch (buttonType) {
    case 'primary':
      return {
        color: colors.white,
        backgroundColor: colors.plandayLightBlue,
        hover: {
          backgroundColor: colors.plandayDarkerBlue,
          transition: 'border, box-shadow, background-color, 100ms ease-out',
          boxShadow: '0 2px 4px 0 rgba(22, 139, 193, 0.5)',
        },
      };
  }
};
