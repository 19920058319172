/** @jsx jsx */
import {jsx} from 'theme-ui';
import PropTypes from 'prop-types';

import * as styles from './styles';

const Button = ({link, text, type}) => (
  <a href={link} sx={styles.wrapper(type)}>
    {text}
  </a>
);

Button.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Button;
