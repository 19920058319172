export const getBaseUrl = () => {
  if (typeof window === 'undefined') {
    return;
  }

  const origin = window.location.origin;

  return origin.includes('planday.com')
    ? 'https://openapi.planday.com'
    : 'https://openapi.stag.planday.cloud';
};
